import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import {
    addCourseContentFeedbackApi, updateStudentFeedbackApi,
} from '../../api/endpoints';
import ErrorModal from '../../Components/auth/ErrorModal';
import Select from 'react-select';
//import '../../Styles/Students.css';
import moment from 'moment';
import { useTheme,useMediaQuery } from "@mui/material";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "react-datetime/css/react-datetime.css";
import CustomOption from '../../Components/Test/CustomOption';
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        color: '#39444e',
        borderColor: state.isFocused ? '' : '#39444e',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#39444e' : '#39444e'
        },
        '@media (max-width: 768px)': {
            fontSize: '12px',
            width: '105%'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#39444e',
        '@media (max-width: 768px)': {
            fontSize: '12px'
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'white' : state.isFocused ? 'white' : 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: 'white',
            color: 'black'
        },
        '@media (max-width: 768px)': {
            fontSize: '12px',
            width: '70%'
        }
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        '@media (max-width: 768px)': {
            fontSize: '12px'
        }
    })
  };
  

const feedbackOptions = [
    { value: 'Good', label: 'Good' },
    { value: 'Poor', label: 'Poor' },
    { value: 'Average', label: 'Average' },
    { value: 'Excellent', label: 'Excellent' }
];

const StudentFeedback = ({ feedbackId, existingFeedback, onSubmit }) => {
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // Debugging: print feedbackId when the component renders
    console.log("Feedback ID passed to StudentFeedback:", feedbackId);

    useEffect(() => {
        console.log("Existing feedback passed to StudentFeedback:", existingFeedback);

        // Pre-populate the form with existing feedback data if available
        if (existingFeedback) {
            const feedbackOption = feedbackOptions.find(option => option.value === existingFeedback.feedback);
            console.log("Pre-populated feedback option:", feedbackOption);
            setSelectedFeedback(feedbackOption);
            setRemarks(existingFeedback.remarks || '');
        }
    }, [existingFeedback]);

    const handleCloseError = () => {
        setShowError(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Debugging: print feedbackId before making the API call
        console.log("Feedback ID during form submission:", feedbackId);

        // Prepare feedback data from state variables
        const feedbackData = {
            feedback: selectedFeedback ? selectedFeedback.value : null,
            remarks: remarks
        };

        // Debugging: print feedback data before making the API call
        console.log("Feedback data to be submitted:", feedbackData);

        try {
            // Use the update API function, passing the feedbackId and feedbackData
            const result = await updateStudentFeedbackApi(feedbackId, feedbackData);
            console.log("Feedback updated successfully:", result);

            setErrorMessage('Feedback Updated Successfully');
            setShowError(true);
            onSubmit(); // Call the onSubmit callback if needed

            // Reset form states after successful update
            e.target.reset();
        } catch (error) {
            console.error('Failed to update feedback:', error.response ? error.response.data : error);
            alert('Failed to update. Check console for details.');
        }
    };

    return (
        <div>
            <div className='form-queslmss' style={{ backgroundColor: "white", borderColor: "white" }}>
                <div className='feedback-responsive' >
                    <Row>
                        <Col>
                            <form onSubmit={handleSubmit} className='form-queslmss' style={{ backgroundColor: "white" }}>
                                <Row md={12}>
                                    <Col>
                                        <div className='feedback' >
                                            <label className='label7-ques' style={{ color: "black" }}>Feedback</label>
                                            <p></p>
                                            <Select
                                                options={feedbackOptions}
                                                value={selectedFeedback}
                                                onChange={setSelectedFeedback}
                                                placeholder="Select Feedback"
                                                styles={customStyles}
                                            />
                                        </div>
                                    </Col><p></p>
                                    <Col>
                                        <div className='add-profile'>
                                            <label className='label6-ques' style={{ color: "black" }}>Remarks</label>
                                            <p></p>
                                            <input
                                                type="text"
                                                name="remarks"
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                autoComplete="off"
                                                className="input-ques"
                                                style={{ backgroundColor: "white", color: "black", borderColor: "#39444e" }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <p style={{ height: "20px" }}></p>
                                <Row>
                                    <Col></Col>
                                    <Col>  <button type="submit" className='button-ques-save' style={{ width: "100px", height: "40px", fontSize: isMobile?"12px": "15px",marginLeft:isMobile?"70px":"0px" }}>
                                        Update
                                    </button></Col>
                                    <Col></Col>
                                </Row>
                                <div>

                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
                <p></p>
            </div>
            {/* Error modal to display success or failure message */}
            <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />
        </div>
    );
};

export default StudentFeedback;

