import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Pagination } from 'react-bootstrap';
import { getStudents_Course_LMS_API } from '../../api/endpoints';
import { FaPlay, FaExpand, FaCompress } from 'react-icons/fa';
import StudentFeedback from './StudentFeedback';
import Next from '../../assets/Images/nextarrow.png';
import Back from '../../assets/Images/backarrow.png';
import ErrorModal from "../../Components/auth/ErrorModal";
import '../../Styles/Students.css';
import { useTheme,useMediaQuery } from "@mui/material";
const LearningMaterial = ({ username }) => {
    const [testcontents, setTestcontents] = useState([]);
    const [showModal1, setShowModal1] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [currentContentIndex, setCurrentContentIndex] = useState(0);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState('');
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocEmbed, setSelectedDocEmbed] = useState('');
    const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false); // Manage feedback modal visibility
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const videoRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [selectedContentType, setSelectedContentType] = useState('All');
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // const { searchQuery } = useContext(SearchContext);
   

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Calculate the items to display based on the current page and items per page
    const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return testcontents.slice(startIndex, endIndex);
    };

    // Get total pages
    const totalPages = Math.ceil(testcontents.length / itemsPerPage);

    const getPaginationItems = () => {
        const items = [];
        let startPage, endPage;
    
        if (totalPages <= 3) {
          startPage = 1;
          endPage = totalPages;
        } else if (currentPage === 1) {
          startPage = 1;
          endPage = 3;
        } else if (currentPage === totalPages) {
          startPage = totalPages - 2;
          endPage = totalPages;
        } else {
          startPage = currentPage - 1;
          endPage = currentPage + 1;
        }
    
        for (let i = startPage; i <= endPage; i++) {
          items.push(
            <Pagination.Item
              key={i}
              active={i === currentPage}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </Pagination.Item>
          );
        }
    
        return items;
      };

    useEffect(() => {
        getTestcontents();
    }, []);

    const getTestcontents = () => {
        getStudents_Course_LMS_API(username)
            .then(data => {
                setTestcontents(data);
                console.log('LMS Contents: ', data)
            })
            .catch(error => console.error('Error fetching test contents:', error));
    };

    const handlePlayVideo = (videoUrl) => {
        setSelectedVideoUrl(videoUrl);
        setShowModal1(true);
    };
    /*
        const handleOpenDocument = (docUrl) => {
            setSelectedDocUrl(docUrl);
            setShowDocumentModal(true);
        };*/
   
    const handleOpenDocument = (embedCode) => {
        setSelectedDocEmbed(embedCode);
        setShowDocumentModal(true);
    };


    const toggleFullScreen = () => {
        const elem = videoRef.current;
        if (!isFullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
        } else {
            if (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
        setIsFullScreen(!isFullScreen);
    };


    useEffect(() => {
        // Disable right-click context menu
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        // Disable copy events
        const handleCopy = (e) => {
            e.preventDefault();
        };

        // Optionally, disable screenshot (making it more difficult)
        const handleKeyDown = (e) => {
            if (
                e.key === 'PrintScreen' ||
                (e.ctrlKey && e.shiftKey && e.key === 'S') // Windows Snipping Tool
            ) {
                e.preventDefault();
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('copy', handleCopy);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        const handleCopy = (e) => {
            e.preventDefault();
        };

        const handleKeyDown = (e) => {
            if (
                e.key === 'PrintScreen' ||
                (e.ctrlKey && e.shiftKey && e.key === 'S')
            ) {
                e.preventDefault();
            }
        };

        if (showDocumentModal) {
            document.addEventListener('contextmenu', handleContextMenu);
            document.addEventListener('copy', handleCopy);
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showDocumentModal]);


    const handleOpenFeedback = (id) => {
        console.log('Selected Feedback ID:', id);
        setSelectedFeedbackId(id);
        setShowFeedbackModal(true); // Open feedback modal
    };

    const handleCloseFeedback = () => {
        setShowFeedbackModal(false); // Close feedback modal
        // handleNextTopic(); // Move to the next topic after feedback submission
    };

    // Handle cancel button to close modal and open feedback
    const handleCancelAndOpenFeedback = () => {
        // Close the current modals
        setShowModal1(false); // Close video modal
        setShowDocumentModal(false); // Close document modal

        // Open feedback form for the current topic
        handleOpenFeedback(testcontents[currentContentIndex]?.id);
    };
    const [formCompleted, setFormCompleted] = useState(false); // State to track form completion

    const handleCloseModal = () => {
        if (!formCompleted) {

            setErrorMessage('Please make sure to fill out the form completely and submit it');
            setShowError(true);
            handleCloseFeedback();
        } else {
            handleCloseFeedback(); // Call the function to handle closing the modal
        }
    };
   
    const handleCloseError = () => {
        setShowError(false);
    };
    return (
        <div className="no-select no-right-click">
            <div className="no-screenshot-overlay"></div>

            <div className="product-table-container-student" style={{ marginLeft: "0px" }}>
              
                    <div>
                        <div className='table-res'>
                        <table className="product-table" >
                        <thead className="table-thead">
                            <tr>

                                <th>Topic</th>
                                <th>Sub Topic</th>
                                <th style={{ height: "70px" }}>Content URL</th>
                                <th>Actual Content</th>
                                <th>Training Date</th>
                               

                            </tr>
                        </thead>
                        <tbody className="table-tbody">
                            {getPaginatedData()
                                .filter(content => selectedContentType === 'All' || content.content_type === selectedContentType)

                                .map(content => (
                                    <tr key={content.id}>

                                        <td>{content.topic}</td>
                                        <td>{content.sub_topic}</td>
                                        <td>
                                            {content.Content_URL ? (
                                                <Button variant="link" onClick={() => handlePlayVideo(content.Content_URL)} style={{ color: 'white' }}>
                                                    <FaPlay size={20} style={{ color: 'white' }} /><span style={{ color: 'white' }}> Play Video</span>
                                                </Button>
                                            ) : (
                                                <Button variant="link" disabled style={{ color: '#a7b3ba' }}>
                                                    <FaPlay size={20} style={{ color: '#a7b3ba' }} /><span style={{ color: '#a7b3ba' }}> Play Video</span>
                                                </Button>
                                            )}
                                        </td>
                                        <td>
                                            {content.Actual_Content ? (
                                                <Button variant="link" onClick={() => handleOpenDocument(content.Actual_Content)} style={{ color: 'white' }}>
                                                    Open File
                                                </Button>
                                            ) : (
                                                <Button variant="link" disabled style={{ color: '#a7b3ba' }}>
                                                    Open File
                                                </Button>
                                            )}
                                        </td>

                                        <td>{content.Start_Date}</td>
                                      {/*}  <td>{content.End_Date}</td>*/}

                                    </tr>
                                ))}
                        </tbody>
                    </table>
                        </div>
                        <p></p>
                        <p></p>
                        <Form.Group controlId="itemsPerPageSelect" style={{ display: 'flex' }}>
                            <Form.Label className='display'>Display:</Form.Label>
                            <Form.Control
                                className='label-dis'
                                style={{ width: "50px" }}
                                as="select"
                                value={itemsPerPage}
                                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                            </Form.Control>
                        </Form.Group>
                        <Pagination className="pagination-custom" >
                            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                            {getPaginationItems()}
                            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                        </Pagination>
                        {/* Feedback Modal */}
                        <Modal show={showFeedbackModal} onHide={handleCloseModal} size="lg" style={{ marginTop: "40px", width: isMobile?"90%": "25%", marginLeft:  isMobile?"5%":"40%" }}>
                            <Modal.Header closeButton>
                                <Modal.Title>Student Feedback</Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                {selectedFeedbackId && (
                                    <StudentFeedback
                                        feedbackId={selectedFeedbackId}
                                        onSubmit={() => {
                                            setFormCompleted(true); // Set form as completed on submit
                                            handleCloseFeedback();
                                        }}
                                    />
                                )}
                            </Modal.Body>
                        </Modal>
                    </div>
                

                {/* Modal for Video */}
                <Modal show={showModal1} onHide={handleCancelAndOpenFeedback} style={{ marginTop: "50px" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Video Player</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="no-select no-right-click">
                        <div style={{ width: "100%", height: "auto", textAlign: "center" }}>
                            <iframe
                                ref={videoRef}
                                src={selectedVideoUrl}
                                width="100%"
                                height="315"
                                frameBorder="0"
                                allowFullScreen
                                title="Video Player"
                            ></iframe>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancelAndOpenFeedback}>Cancel</Button>
                        <Button variant="secondary" onClick={toggleFullScreen}>
                            {isFullScreen ? <FaCompress /> : <FaExpand />} Toggle Fullscreen
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Modal for Document */}
                <Modal show={showDocumentModal} onHide={handleCancelAndOpenFeedback} size="xl" style={{ marginTop: "40px" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Document Viewer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="no-select no-right-click" >
                        {selectedDocEmbed && <div className="embedded-document" dangerouslySetInnerHTML={{ __html: selectedDocEmbed }} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCancelAndOpenFeedback}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <div className="cui-statusbar"></div>
                <ErrorModal
                    show={showError}
                    handleClose={handleCloseError}
                    errorMessage={errorMessage}
                />
            </div>
        </div>
    );
};

export default LearningMaterial;



