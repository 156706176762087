import React, { useState, useEffect, useContext } from 'react';
import { Table, Form, Pagination } from 'react-bootstrap';
import {
  get_test_group_ReportsAPI,
  deleteTestcadidateApi,
  getTestcandidateApi,
  getTestReports_API,
  getDistinct_test_API
} from '../../api/endpoints';
import { Link } from 'react-router-dom';
import '../../Styles/global.css';
import { testNameContext } from '../Test/context/TestTypeContext';
import Footer from '../../Footer/Footer';
import ErrorModal from '../auth/ErrorModal';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { SearchContext } from '../../AllSearch/SearchContext';
import UploadTestReport from './UploadTestReport';

const TestReports = () => {
  const [testCandidates, setTestCandidates] = useState([]);
  const [distinctTests, setDistinctTests] = useState({});
  const [filters, setFilters] = useState({
    test_name: '',
    college_id: '',
    department_id: '',
    year: ''
  });

  const { setTestName } = useContext(testNameContext);
  const { searchQuery } = useContext(SearchContext);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  
  const [placeholder, setPlaceholder] = useState("Select Test Name");
  const [placeholderDept, setPlaceholderDept] = useState("Select Department");
  const [placeholderYear, setPlaceholderYear] = useState("Select Year");
  const [placeholderClg, setPlaceholderClg] = useState("Select College");

  const refreshTestCandidates = () => {
    getTestCandidates(); // Fetch updated data
};
  const handleCloseError = () => {
    setShowError(false);
  };

  useEffect(() => {
    getDistinctFilters();
    getTestCandidates();
  }, [filters]);

  const getDistinctFilters = () => {
    getDistinct_test_API()
      .then(data => {
        setDistinctTests(data);
      })
      .catch(error => console.error('Error fetching distinct filters:', error));
  };

  const getTestCandidates = () => {
    getTestReports_API(filters)
      .then(data => {
        setTestCandidates(data);
      })
      .catch(error => console.error('Error fetching test candidates:', error));
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    
    // If "All Tests" is selected, reset the placeholder
    if (value === "All Tests") {
      setPlaceholder("All Tests");
    } else {
      setPlaceholder("All Tests");
    }
    //Departments:

    if (value === "All Departments") {
      setPlaceholderDept("All Departments");
    } else {
      setPlaceholderDept("All Departments");
    }
    // Years:

    if (value === "All Years") {
      setPlaceholderYear("All Years");
    } else {
      setPlaceholderYear("All Years");
    }
    // Years:

    if (value === "All Colleges") {
      setPlaceholderClg("All Colleges");
    } else {
      setPlaceholderClg("All Colleges");
    }
  };

  useEffect(() => {
    getTestCandidates();
  }, [filters]);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = testCandidates.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(testCandidates.length / itemsPerPage);
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const getPaginationItems = () => {
    const items = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage === 1) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage === totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div>
      <div className='table-testschedule'>
        <div className="product-table-container">
          <h4>Test Reports</h4>
          <UploadTestReport onUploadSuccess={refreshTestCandidates}/>

          <div className='table-responsive'>
            <table className="product-table">
              <thead className="table-thead" style={{ textAlign: "center" }}>
                <tr className='header-row'>
                  <th className='title-place' style={{ width: "200px", textAlign: "center" }}>
                    <Form.Control
                      as="select"
                      name="college_id"
                      value={filters.college_id}
                      onChange={handleFilterChange}
                      onFocus={() => setPlaceholderClg("All Colleges")} 
                    >
                    <option value="">{placeholderClg}</option>
                      {distinctTests.distinct_colleges?.map(college => (
                        <option key={college.college_id} value={college.college_id}>
                          {college.college_name}
                        </option>
                      ))}
                    </Form.Control>
                  </th>
                  <th style={{ textAlign: "center" }} className='title-place'>
                    <Form.Control
                      as="select"
                      name="test_name"
                      value={filters.test_name}
                      onChange={handleFilterChange}
                      onFocus={() => setPlaceholder("All Tests")} 
                    >
                    <option value="">{placeholder}</option>
                      {distinctTests.distinct_test_names?.map(test => (
                        <option key={test.test_name} value={test.test_name}>
                          {test.test_name}
                        </option>
                      ))}
                    </Form.Control>
                  </th>
                  <th style={{ textAlign: "center" }} className='title-place'>
                    <Form.Control
                      as="select"
                      name="department_id"
                      value={filters.department_id}
                      onChange={handleFilterChange}
                      onFocus={() => setPlaceholderDept("All Departments")} 
                    >
                    <option value="">{placeholderDept}</option>
                      {distinctTests.distinct_departments?.map(department => (
                        <option key={department.department_id} value={department.department_id}>
                          {department.department_name}
                        </option>
                      ))}
                    </Form.Control>
                  </th>
                  <th style={{ textAlign: "center" }} className='title-place'>
                    <Form.Control
                      as="select"
                      name="year"
                      value={filters.year}
                      onChange={handleFilterChange}
                      onFocus={() => setPlaceholderYear("All Years")} 
                    >
                    <option value="">{placeholderYear}</option>
                      {distinctTests.distinct_years?.map(year => (
                        <option key={year.year} value={year.year}>
                          {year.year}
                        </option>
                      ))}
                    </Form.Control>
                  </th>
                  <th style={{ textAlign: "center" }}>Student Count</th>
                </tr>
              </thead>
              <tbody className="table-tbody" style={{ fontSize: '14px' }}>
                {currentData
                  .filter(item =>
                    !searchQuery ||
                    (item.test_name && item.test_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.college_name && typeof item.college_name === 'string' && item.college_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.department_name && typeof item.department_name === 'string' && item.department_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.year && typeof item.year === 'string' && item.year.toLowerCase().includes(searchQuery.toLowerCase()))
                  )
                  .map((item) => (
                    <tr key={item.id} className="table-row">
                      <td style={{ textAlign: "center" }}>{item.college_name}</td>
                      <td style={{ textAlign: "center" }}>{item.test_name}</td>
                      <td style={{ textAlign: "center" }}>{item.department_name}</td>
                      <td style={{ textAlign: "center" }}>{item.year}</td>
                      <td style={{ textAlign: 'center' }}>
                        <Link
                          to={`/test-result/cc/?test_name=${encodeURIComponent(item.test_name)}&college_id=${encodeURIComponent(item.college_name)}&department_id=${encodeURIComponent(item.department_name)}&year=${encodeURIComponent(item.year)}`}
                          style={{ color: "white" }}
                        >
                          {item.total_students}
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className='dis-page'>
            <Form>
              <Form.Group controlId="itemsPerPageSelect" style={{ display: 'flex' }}>
                <Form.Label style={{ marginRight: '10px' }}>Display:</Form.Label>
                <Form.Control
                  className='label-dis'
                  style={{ width: "50px", boxShadow: 'none', outline: 'none' }}
                  as="select"
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                </Form.Control>
              </Form.Group>
            </Form>
            <Pagination className="pagination-custom">
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {getPaginationItems()}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      </div>
      <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />
    </div>
  );
};

export default TestReports;
