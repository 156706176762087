
import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link,Navigate } from 'react-router-dom';
import Sidebar from './PlacementAdmin/Sidebar'
import Dashboard from './PlacementAdmin/Dashboard/Dashboard';
import Database from './Components/Database/Database';
import { SearchProvider } from './AllSearch/SearchContext';
import UploadStudentProfile from './Components/Database/UploadStudent';
import Settings from './Components/Database/settings';
import Testaccess from './Components/Test/Testaccess';
import './App.css'
import LoginCreate from './PlacementAdmin/Database/LoginCreate';
import Footer from './Footer/Footer';
import { ContextProvider } from './Components/Test/context/TestTypeContext';
import MCQForm from './Components/Questions/MCQForm';
import QuesPaperTb from './Components/Questions/QuesPaperTb';
import Update_MCQForm from './Components/Questions/Update_MCQForm';
import QuestionPaperMCQ from './Components/Questions/QuestionPaperMCQ';
import QuestionPaperCode from './Components/Questions/QuestionPaperCode';
import Update_CodeForm from './Components/Questions/Update_CodeForm';
import TestReport from './Components/Reports/TestReport';
import UpdateTestAccessForm from './Components/Test/UpdateTest';
import NonDatabaseForm from './Components/Test/NonDatabaseForm';
import TestResult from './Components/Reports/TestResult';
import TestReports from './Components/Reports/TestReportold';
import TestSchedules from './Components/Test/TestSchedules';
import AddDBCandidates from './Components/Test/AddCandidates/AddDBCandidates';
import AddNonDBCandidates from './Components/Test/AddCandidates/AddNonDBCandidates';

import Header from './Header/Header';
import { CssBaseline } from '@mui/material';
import ThemeContextProvider from './ThemeContext';

import BinaryToImage from './Components/Questions/BinaryToImage';

import CCAnnouncement from './Components/Announcement/Announcement';
import StudentResults from './Components/Reports/StudentResults';
import AddQuestions from './Components/Questions/AddQuestions';
import AddQuestionsCode from './Components/Questions/AddQuestionsCode';
import QuestionPaper from './Components/Questions/updateQuestionPaper';
import Uploadjoboffers from './Components/JobPost/JobOffer';

const PlaecementAdmin = ({ collegeName, username,userRole }) => {
  const [theme, setTheme] = useState('black');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
 
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

 
  
  return (
    <div className='App-header'  style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router>
        <div className={`app ${theme}`}>
          <SearchProvider>

            <div className='content-wrapper'>
              <ContextProvider >
                <ThemeContextProvider>
                  <CssBaseline />
                  <Header theme={theme} toggleTheme={toggleTheme}  username={username}  userRole={userRole} />
                  <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                  <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}` } style={{ marginTop: '60px'}}>
                     <Routes>
                      <Route path="/" element={<Dashboard />} />
                     

                      <Route path="/question/mcq" element={<QuestionPaperMCQ />} />
                      <Route path="/question/code" element={<QuestionPaperCode />} />

                      <Route path="/question" element={<MCQForm />} />
                      <Route path="/question-paper-table" element={<QuesPaperTb />} />
                      <Route path="/update-mcq-form/:id" element={<Update_MCQForm />} />
                      <Route path="/update-code-form/:id" element={<Update_CodeForm />} />
                      <Route path="/add-questions/:id" element={<AddQuestions />} />
                      <Route path="/add-questions/code/:id" element={<AddQuestionsCode />} />
                      <Route path="/update-paper/:id" element={<QuestionPaper/>}/>
                     
                      <Route path="/test-report/:test_name" element={<TestReport />} />
                      <Route path="/test-result/:test_name" element={<TestResult />} />
                      {/*}  <Route path="/lms/content-map" element={<ContentMap />} />  */}


                      <Route path="/reports" element={<TestReports />} />
                      <Route path="/job" element={<Uploadjoboffers />} />
                      
                      <Route path="/add-candidate/:test_name" element={<AddDBCandidates />} />
                      <Route path="/add/non-db-candidate" element={<AddNonDBCandidates />} />


                      <Route path="/testaccess" element={<Testaccess />} />
                      <Route path="/database/upload-student" element={<UploadStudentProfile />} />
                     
                      <Route path="/test/test-access" element={<Testaccess />} />
                      <Route path="/test-access/non-db/" element={<NonDatabaseForm />} />
                      <Route path="/test/test-schedules/" element={<TestSchedules />} />
                      <Route path="/testschedule/" element={<TestSchedules />} />

                      <Route path="/update-test/:test_name" element={<UpdateTestAccessForm/>}/>
                      <Route path="/database/settings" element={<Settings />} />
                      <Route path="/database/login" element={<LoginCreate />} />
                     
                     
                       <Route path="/test-result/cc/" element={<StudentResults/>}/>
                      <Route path="/announce/" element={<CCAnnouncement />} />
                      <Route path="/binary/to/image" element={<BinaryToImage />} />
                    {/*}  <Route path="index.html" element={<Navigate to="/" />} />   */}
                    </Routes>
                  </div>
              
                </ThemeContextProvider>
              </ContextProvider>
            </div>
          </SearchProvider>
        </div>
      </Router>
      <Footer/>
    </div>
  );
};

export default PlaecementAdmin;
