//PlacementOfficer.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Sidebar from './PlacementOfficer/Sidebar'
import Dashboard from './PlacementOfficer/Dashboard/Dashboard'
import { SearchProvider } from './AllSearch/SearchContext';
import Settings from './PlacementOfficer/Database/settings';
import Testaccess from './PlacementOfficer/Test/Testaccess'
import './App.css';
import { ContextProvider } from './PlacementOfficer/Test/context/TestTypeContext';
import MCQForm from './PlacementOfficer/Questions/MCQForm';
import QuesPaperTb from './PlacementOfficer/Questions/QuesPaperTb';
import Update_MCQForm from './PlacementOfficer/Questions/Update_MCQForm';
import QuestionPaperMCQ from './PlacementOfficer/Questions/QuestionPaperMCQ';
import QuestionPaperCode from './PlacementOfficer/Questions/QuestionPaperCode';
import Update_CodeForm from './PlacementOfficer/Questions/Update_CodeForm';
import TestReport from './PlacementOfficer/Reports/TestReport';
import UpdateTestAccessForm from './PlacementOfficer/Test/UpdateTest';
import NonDatabaseForm from './PlacementOfficer/Test/NonDatabaseForm';
import TestResult from './PlacementOfficer/Reports/TestResult';
import TestReports from './PlacementOfficer/Reports/TestReportold';
import TestSchedules from './PlacementOfficer/Test/TestSchedules';
import AddDBCandidates from './PlacementOfficer/Test/AddCandidates/AddDBCandidates';
import AddNonDBCandidates from './PlacementOfficer/Test/AddCandidates/AddNonDBCandidates';
import  FilterCandidatesDownload from './PlacementOfficer/Database/FilterDB';
import Header from './Header/Header';
import { CssBaseline } from '@mui/material';
import ThemeContextProvider from './ThemeContext';
import Uploadjoboffers from './PlacementOfficer/Database/UploadJoboffer';

import StudentList from './PlacementOfficer/Database/Studentslist';
import Uploadstudentdata from './PlacementOfficer/Database/uploadtable';
import EligibleStudents from './PlacementOfficer/Database/EligibleStudents';
import LoginCreate from './PlacementOfficer/Database/LoginCreate';
import PlacementReport from './PlacementOfficer/Reports/PlacementReport';
import UploadStudentProfile from './PlacementOfficer/Database/UploadStudent';
import MCQForms from './PlacementOfficer/Test/MCQquestions';
import CodeForms from './PlacementOfficer/Test/CodingQuestions';
import PAnnouncement from './PlacementOfficer/Announcement/Announcement';
import StudentResults from './PlacementOfficer/Reports/StudentResults';
import AddQuestionsCode from './PlacementOfficer/Questions/AddQuestionsCode';
import AddQuestions from './PlacementOfficer/Questions/AddQuestions';
import QuestionPaper from './PlacementOfficer/Questions/updateQuestionPaper';


const PlacementOfficer = ({ collegeName, username, institute,userRole }) => {
  const [theme, setTheme] = useState('black');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  return (
    <div className='App-header' style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Router>
        <div className={`app ${theme}`}>
          <SearchProvider>

            <div className='content-wrapper'>
              <ContextProvider >
                <ThemeContextProvider>
                  <CssBaseline />
                  <Header theme={theme} toggleTheme={toggleTheme} username={username} collegeName={collegeName} institute={institute} userRole={userRole} />
                  <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                  <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`} style={{ marginTop: '60px' }}>
                    <Routes>
                      <Route path="/" element={<Dashboard username={username} collegeName={collegeName} institute={institute} />} />
                      {/*}  <Route path="/lms/upload-video" element={<LearningMaterial />} />*/}

                      <Route path="/question/mcq" element={<QuestionPaperMCQ username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/question/code" element={<QuestionPaperCode username={username} collegeName={collegeName} institute={institute} />} />

                      <Route path="/question" element={<MCQForm username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/question-paper-table" element={<QuesPaperTb username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/update-mcq-form/:id" element={<Update_MCQForm username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/update-code-form/:id" element={<Update_CodeForm username={username} collegeName={collegeName} institute={institute} />} />

                      <Route path="/test-report/:test_name" element={<TestReport username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/test-result/:test_name" element={<TestResult username={username} collegeName={collegeName} institute={institute} />} />
                      {/*}  <Route path="/lms/content-map" element={<ContentMap />} />  */}
                      <Route path="/test-result/placement/" element={<StudentResults />} />
                      <Route path="/announce/" element={<PAnnouncement collegeName={collegeName} institute={institute} />} />
                      <Route path="/reports/test-report" element={<TestReports username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/reports/placement-report" element={<PlacementReport username={username} collegeName={collegeName} institute={institute} />} />
                     
                      <Route path="/add-candidate/:test_name" element={<AddDBCandidates username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/add/non-db-candidate" element={<AddNonDBCandidates username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path='/eligible-student/:job_id' element={<StudentList />} />
                      <Route path='/students/eligible-student/:job_id/:round_of_interview' element={<EligibleStudents />} />

                      <Route path="/mcq-form/:id" element={<MCQForms />} />
                      <Route path="/code-form/:id" element={<CodeForms />} />
                      <Route path="/add-questions/code/:id" element={<AddQuestionsCode />} />
                      <Route path="/add-questions/:id" element={<AddQuestions />} />
                      <Route path="/update-paper/:id" element={<QuestionPaper/>}/>

                      <Route path="/database/upload-offer" element={<Uploadjoboffers username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/database/upload-student" element={<UploadStudentProfile username={username} collegeName={collegeName} institute={institute} />} />
                    
                      <Route path='/test/test-access' element={<Testaccess username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/test-access/non-db/" element={<NonDatabaseForm username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/test/test-schedules/" element={<TestSchedules username={username} collegeName={collegeName} institute={institute} />} />

                      <Route path="/update-test/:test_name" element={<UpdateTestAccessForm username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/database/settings" element={<Settings username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/database/login" element={<LoginCreate username={username} collegeName={collegeName} institute={institute} />} />
                      <Route path="/database/filterdb" element={<FilterCandidatesDownload username={username} collegeName={collegeName} institute={institute} />} />
                      
                      <Route path="/uploadstudentdata" element={<Uploadstudentdata  institute={institute} collegeName={collegeName}/>} />

                
                      {/*}  <Route path="index.html" element={<Navigate to="/" />} />   */}
                    </Routes>
                  </div>

                </ThemeContextProvider>
              </ContextProvider>
            </div>
          </SearchProvider>
        </div>
      </Router>
    </div>
  );
};

export default PlacementOfficer;
