import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Button, Table, Form, Pagination } from "react-bootstrap";
import { getTrainer_Course_LMS_API } from "../../api/endpoints";
import { FaPlay, FaExpand, FaCompress } from "react-icons/fa";
import { SearchContext } from "../../AllSearch/SearchContext";
import TrainerReportForm from "./TrainerReport";
import ErrorModal from "../../Components/auth/ErrorModal";

const LearningMaterial = ({ username, institute }) => {
  const [testcontents, setTestcontents] = useState([]);
  const [selectedContentType, setSelectedContentType] = useState("All");
  const [showModal1, setShowModal1] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocEmbed, setSelectedDocEmbed] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const { searchQuery } = useContext(SearchContext);
  const [showTrainerReport, setShowTrainerReport] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [courseScheduleId, setCourseScheduleId] = useState(null); // or any default value
  
  const handleCloseTrainerReport = () => {
    if (!isFormFilled) {
      setErrorMessage(
        "Trainer should fill the form. Are you sure you want to close?"
      );
      setShowError(true);
      setShowTrainerReport(false);
    } else {
      setShowTrainerReport(false); // Close the modal
    }
  };
  const handleCloseError = () => {
    setShowError(false);
  };
  //error message

  //prtscr
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "PrintScreen") {
        event.preventDefault();
        // Optionally, display a message to the user or take some action
        alert("Screenshot functionality is restricted.");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  
  
  const handleOpenDocument = (embedCode, contentId) => {
    // Log received parameters
    console.log("Embed Code:", embedCode);
    console.log("Received Content ID:", contentId);

    // Check if contentId is valid before setting
    if (contentId !== undefined) {
        setSelectedDocEmbed(embedCode);
        setCourseScheduleId(contentId); // Set the content ID here
        setShowDocumentModal(true); // Open the modal
    } else {
        console.error("Content ID is undefined. Cannot open document.");
    }

    // Log current state (note this will show the previous value)
    console.log("Before opening document modal:", courseScheduleId);
};

// useEffect to track updates
useEffect(() => {
    console.log("Updated courseScheduleId:", courseScheduleId);
}, [courseScheduleId]);

  
  const handleCloseDocumentModal = () => {
   
    setShowDocumentModal(false); // Close document modal
    setShowTrainerReport(true); // Open Trainer Report after closing document
  };

  //   const handleCloseTrainerReport = () => {
  //     setShowTrainerReport(false);
  //   };

  const handlePlayVideo = (videoUrl) => {
    setSelectedVideoUrl(videoUrl);
    setShowModal1(true);
  };

  // Fullscreen toggle function for the video player
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen); // Toggle fullscreen state
  };

  // Fetch data
  useEffect(() => {
    getTrainer_Course_LMS_API(username)
      .then((data) => {
        setTestcontents(data);
        
      })
      .catch((error) => console.error("Error fetching test contents:", error));
  }, [username]);
 
 
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return testcontents.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(testcontents.length / itemsPerPage);
  const getPaginationItems = () => {
    const items = [];
    let startPage, endPage;

    if (totalPages <= 3) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage === 1) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage === totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div>
      <div className="product-table-container-trainer" style={{ marginLeft: "0px" }}>
        <div className="table-responsive">
          <table className="product-table">
            <thead className="table-thead">
              <tr>
                <th>Topic</th>
                <th>Sub Topic</th>
                <th>Content URL</th>
                <th>Actual Content</th>
                <th>Start Date</th>
                <th>Validity Date</th>
              </tr>
            </thead>
            <tbody className="table-tbody">
              {getPaginatedData()
                .filter(
                  (content) =>
                    selectedContentType === "All" ||
                    content.content_type === selectedContentType
                )
                .map((content) => (
                  <tr key={content.id}>
                    <td>{content.topic}</td>
                    <td>{content.sub_topic}</td>
                    <td>
                      {content.Content_URL ? (
                        <Button
                          variant="link"
                          onClick={() => handlePlayVideo(content.Content_URL)}
                          style={{ color: "white" }}
                        >
                          <FaPlay size={20} style={{ color: "white" }} />
                          Play Video
                        </Button>
                      ) : (
                        <Button
                          variant="link"
                          disabled
                          style={{ color: "#a7b3ba" }}
                        >
                          <FaPlay size={20} style={{ color: "#a7b3ba" }} />
                          Play Video
                        </Button>
                      )}
                    </td>
                    <td>
                      {content.Actual_Content ? (
                        <Button
                          variant="link"
                          onClick={() => {
                            console.log("Content before opening:", content);
                            handleOpenDocument(content.Actual_Content, content.id);
                          }}
                          style={{ color: "white" }}
                        >
                          Open File
                        </Button>
                      ) : (
                        <Button
                          variant="link"
                          disabled
                          style={{ color: "#a7b3ba" }}
                        >
                          Open File
                        </Button>
                      )}
                    </td>
                    <td>{content.Start_Date}</td>
                    <td>{content.End_Date}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Form.Group controlId="itemsPerPageSelect" style={{ display: "flex" }}>
          <Form.Label className="display-trainer">Display:</Form.Label>
          <Form.Control
            className="label-dis"
            style={{ width: "50px" }}
            as="select"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
          </Form.Control>
        </Form.Group>
        <Pagination className="pagination-custom">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {getPaginationItems()}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>

        {/* Modal for document and Trainer Report */}
        <Modal
          show={showDocumentModal}
          onHide={handleCloseDocumentModal}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Document Viewer</Modal.Title>
          </Modal.Header>
          <Modal.Body className="no-select no-right-click">
            {selectedDocEmbed && (
              <div
                className="embedded-document"
                dangerouslySetInnerHTML={{ __html: selectedDocEmbed }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleCloseDocumentModal()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Modal for Trainer Report Form */}
        <Modal
          show={showTrainerReport}
          onHide={handleCloseTrainerReport}
          size="lg"
          style={{ width: "100%", alignItems: "center" }}
        >
          <Modal.Header
           
            closeButton
          >
            <Modal.Title>Trainer Report Form</Modal.Title>
          </Modal.Header>
          <Modal.Body >
          <TrainerReportForm username={username} courseScheduleId={courseScheduleId} />
          </Modal.Body>
        </Modal>

        {/* Modal for Video Player */}
        <Modal show={showModal1} onHide={() => setShowModal1(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Video Player</Modal.Title>
          </Modal.Header>
          <Modal.Body className="no-select no-right-click">
            <div style={{ position: "relative" }}>
              <video
                ref={videoRef}
                width="100%"
                height="auto"
                controls
                src={selectedVideoUrl}
              />
              <Button
                variant="link"
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "white",
                }}
                onClick={toggleFullScreen}
              >
                {isFullScreen ? (
                  <FaCompress size={20} />
                ) : (
                  <FaExpand size={20} />
                )}
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal1(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <ErrorModal
          show={showError}
          handleClose={handleCloseError}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};

export default LearningMaterial;
