import React, { useState } from 'react';
import { TestReports_Upload_API, TestReports_Update_API } from '../../api/endpoints';
import ErrorModal from '../auth/ErrorModal';
import Upload from '../../assets/Images/upload.png';

import Update from '../../assets/Images/update-icon.png';
const UploadTestReport = ({ onUploadSuccess }) => {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);  // Separate loading state for upload
    const [isUpdating, setIsUpdating] = useState(false);    // Separate loading state for update
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseError = () => {
        setShowError(false);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            setErrorMessage('Please select a file to upload');
            setShowError(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            setIsUploading(true); // Set uploading state
            const response = await TestReports_Upload_API(formData);

            if (response.status === 200) {
                setErrorMessage('File uploaded successfully!');
                setShowError(true);
                onUploadSuccess();
            } else {
                setErrorMessage('Error processing file');
                setShowError(true);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(`Error: ${error.response.data.error}`);
                setShowError(true);
            } else {
                setErrorMessage('An error occurred during file upload');
                setShowError(true);
            }
        } finally {
            setIsUploading(false); // Reset uploading state
        }
    };

    const handleUpdate = async () => {
        if (!file) {
            setErrorMessage('Please select a file to update');
            setShowError(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            setIsUpdating(true); // Set updating state
            const response = await TestReports_Update_API(formData);

            if (response.status === 200) {
                setErrorMessage('File updated successfully!');
                setShowError(true);
            } else {
                setErrorMessage('Error processing update');
                setShowError(true);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(`Error: ${error.response.data.error}`);
                setShowError(true);
            } else {
                setErrorMessage('An error occurred during file update');
                setShowError(true);
            }
        } finally {
            setIsUpdating(false); // Reset updating state
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} >
                <div className='upload-repo'
                style={{ display: 'flex', borderRadius: '8px', border: '1px solid', width: 'fit-content' }}>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileChange}
                        accept=".xlsx, .xls"
                        required
                        style={{ margin: '10px', paddingTop: '8px' }}
                    />
                    <div className='button-repo'>
                    <button style={{ marginRight: '20px',margin:'10px',width:"100px" }} className="button-data upload-button-db" type="submit" disabled={isUploading || isUpdating}>
                    {isUploading ? 'Uploading...' : <><img src={Upload} alt="Upload" className='nextarrow' /> Upload</>}</button>
               

            
                <button style={{ margin: '10px',width:"100px" }} className="button-data-update upload-button-db" onClick={handleUpdate} disabled={isUpdating || isUploading}>
                {isUpdating ? 'Updating...' : <><img src={Update} alt="Update" className='nextarrow' /> Update</>}
             
                </button></div>
          
            </div>
            </form>

            <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />
        </div>
    );
};

export default UploadTestReport;
