
import React from 'react';
import { Modal } from 'react-bootstrap';
import './ErrorModal.css'; // Assuming you create a separate CSS file
import { useTheme,useMediaQuery } from "@mui/material";
const ErrorModal = ({ show, handleClose, errorMessage }) => {
    const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Modal show={show} onHide={handleClose} centered dialogClassName="custom-modal-width" style={{marginTop: isMobile?"-220px":"-150px", width: isMobile?"200px":"300px",marginLeft: isMobile? "90px" : "700px"}}>
            <Modal.Header closeButton style={{ height: isMobile?"1px": "5px",backgroundColor: "#F1A128", color: "black", borderBottom: 'none' }}>
            </Modal.Header>
            <Modal.Body style={{ fontSize: isMobile?'15px':'16px', backgroundColor: "#F1A128", marginLeft:"20px", padding: '20px',fontWeight:"bold" }}>
                <div style={{ justifyContent: 'center' }}>
                    {errorMessage}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ErrorModal;
