import React, { useState, useEffect } from 'react';
import { Container, Card, Col, Row, Form, Button } from 'react-bootstrap';
import {
    updateLoginApi,
    getcollegeApi,
    LoginDataApi,
    addTrainer_username_API
} from '../../api/endpoints';
import ErrorModal from './ErrorModal';
import Footer from '../../Footer/Footer';
import Select from 'react-select';
import { useParams } from 'react-router-dom'; 

import LoginTable from './LoginTable';
import Next from '../../assets/Images/nextarrow.png';
import back from '../../assets/Images/backarrow.png';
const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#39444e',
        color: '#fff', // Text color
        borderColor: state.isFocused ? '' : '#ffff', // Border color on focus
        boxShadow: 'none', // Remove box shadow
        '&:hover': {
            borderColor: state.isFocused ? '#ffff' : '#ffff' // Border color on hover
        },
        '&.css-1a1jibm-control': {
            // Additional styles for the specific class
        },
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px', // Smaller font size

            width: '96%'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#ffff', // Text color for selected value
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px' // Smaller font size
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#39444e' : state.isFocused ? '#39444e' : '#39444e',
        color: '#ffff', // Text color
        '&:hover': {
            backgroundColor: '#39444e', // Background color on hover
            color: '#ffff' // Text color on hover
        },
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px',// Smaller font size
            width: '96%'
        }
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#39444e',
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px' // Smaller font size
        }
    })
};

function UpdateLogin() {
    const { user_name } = useParams(); // Get the username from the URL
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email_id, setemail_id] = useState('');
    const [role, setRole] = useState('');
    const [instituteName, setInstituteName] = useState([]); // For dropdown
    const [selectedInstitute, setSelectedInstitute] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Fetch user data and available colleges
    useEffect(() => {
        console.log('Fetching data for username:', user_name); // Step 1: Fetch user data by username

        if (user_name) {
            LoginDataApi(user_name)
                .then(data => {
                    console.log('Received user data:', data); // Step 2: Log fetched user data
                    setUsername(data.user_name);
                    setemail_id(data.email_id);
                    setPassword(data.password);
                    setRole(data.role);
                    setSelectedInstitute({ value: data.college_id, label: data.college_name });
                })
                .catch(error => console.error('Error fetching user data:', error));
        }

        // Fetch list of colleges for the dropdown
        getcollegeApi()
            .then(data => {
                console.log('Received college data:', data); // Step 3: Log fetched college data
                setInstituteName(data.map(item => ({ value: item.id, label: item.college })));
            })
            .catch(error => console.error('Error fetching college data:', error));
    }, [user_name]);

    // Function to handle form submission
    const handleRegister = (e) => {
        e.preventDefault();
        console.log('Handling form submission'); // Step 4: Log form submission event

        const collegeId = selectedInstitute ? selectedInstitute.value : null;
        console.log('Selected collegeId:', collegeId); // Step 5: Log selected college ID

        if (username.trim() && password.trim() && role.trim()) {
            const dtmCreated = new Date();
            let dtmTrainer = null;

            if (role === 'Trainer') {
                dtmTrainer = new Date(dtmCreated);
                dtmTrainer.setMinutes(dtmTrainer.getMinutes() + 5);
            }

            const requestData = {
                email_id: email_id,
                user_name: username,
                password: password,
                role: role,
                college_id: collegeId,
                dtm_created: dtmCreated.toISOString(),
                dtm_trainer: dtmTrainer ? dtmTrainer.toISOString() : null,
            };

            console.log('Request data being sent to API:', requestData); // Step 6: Log request payload

            updateLoginApi(user_name, requestData)
                .then(result => {
                    console.log('Update successful:', result); // Step 7: Log API response
                    setErrorMessage('Update Successful');
                    setShowError(true);

                    if (role === 'Trainer') {
                        handleAddTrainer(username);
                    }
                })
                .catch(error => {
                    console.error('Update failed:', error); // Step 8: Log update failure
                    setErrorMessage('Update Failed');
                    setShowError(true);
                });
        } else {
            console.log('Invalid form data. Ensure all fields are filled.'); // Step 9: Log invalid form submission
            setErrorMessage('Please fill in all required fields.');
            setShowError(true);
        }
    };

    // Function to handle adding a trainer
    async function handleAddTrainer(userName) {
        try {
            console.log('Adding trainer with username:', userName); // Step 10: Log trainer addition
            const response = await addTrainer_username_API(userName);
            console.log('Trainer added successfully:', response); // Step 11: Log trainer addition success
        } catch (error) {
            console.error('Failed to add trainer:', error); // Step 12: Log trainer addition failure
        }
    }

    const handleCloseError = () => {
        setShowError(false);
    };

    return (
        <div className="form-ques">
            <Row>
                <form onSubmit={handleRegister} className="form-ques">
                    <Row md={12}>
                        <Col>
                            <div controlId="username" className="add-profile">
                                <label className="label5-ques">User Name</label><p></p>
                                <input
                                    type="text"
                                    placeholder="Enter Username"
                                    className="input-ques"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div controlId="userType" className="add-profile">
                                <label className="label5-ques">User Type</label><p></p>
                                <select
                                    className="input-ques"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                >
                                    <option value="">Select role</option>
                                    <option value="Placement Officer">Placement Officer</option>
                                    <option value="Training admin">Training admin</option>
                                    <option value="Placement admin">Placement admin</option>
                                    <option value="Super admin">Super admin</option>
                                    <option value="Student">Student</option>
                                    <option value="Trainer">Trainer</option>
                                </select>
                            </div>
                        </Col>
                    </Row><p></p>
                    <Row md={12}>
                        <Col>
                            <div controlId="email_id" className="add-profile">
                                <label className="label5-ques">Email</label><p></p>
                                <input
                                    className="input-ques"
                                    type="text"
                                    placeholder="Enter email_id"
                                    value={email_id}
                                    onChange={(e) => setemail_id(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </Col>
                        <Col>
                            <div controlId="instituteName" className="add-profile">
                                <label className="label5-ques">Institute Name</label><p></p>
                                <Select
                                    options={instituteName}
                                    value={selectedInstitute}
                                    onChange={setSelectedInstitute}
                                    placeholder="Select College"
                                    styles={customStyles}
                                />
                            </div>
                        </Col>
                    </Row><p></p>
                    <Row md={12}>
                        <Col>
                            <div controlId="password" className="add-profile">
                                <label className="label5-ques">Password</label><p></p>
                                <input
                                    className="input-ques"
                                    type="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </Col><Col></Col>
                    </Row><p style={{height:"40px"}}></p>

                    <Row className="justify-content-center">
                        <Col></Col>
                        <Col>
                            <div className="button-container-lms-create" style={{marginLeft:"120px"}}>
                                <button type="submit" className="button-ques-save">
                                    Update
                                </button>
                            </div>
                        </Col>
                        <Col></Col>
                    </Row>
                </form>
                <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />
            </Row>
        </div>
    );
}

export default UpdateLogin;
