import React, { useState, useEffect, useContext } from 'react';
import { getCourseContentFeedbackApi } from '../../../src/api/endpoints';
import '../../Styles/TrainingAdmin.css';
import back from '../../assets/Images/backarrow.png'

import Next from '../../assets/Images/nextarrow.png';
import { SearchContext } from '../../AllSearch/SearchContext';

const StudentsFeedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [currentTrainerIndex, setCurrentTrainerIndex] = useState(0); // Track the index of the currently displayed trainer
  const [error, setError] = useState(null);
  const [trainerNameFilter, setTrainerNameFilter] = useState('');
  const [feedbackFilter, setFeedbackFilter] = useState('');
  const { searchQuery } = useContext(SearchContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Fetch trainer data when the component mounts
    fetchFeedback();
  }, [trainerNameFilter, feedbackFilter, searchQuery]);

  const fetchFeedback = async () => {
    try {
      const response = await getCourseContentFeedbackApi();
      // Filter response based on filter criteria
      const filteredFeedback = response.filter(item =>
        (trainerNameFilter ? item.trainer_name.toLowerCase().includes(trainerNameFilter.toLowerCase()) : true) &&
        (feedbackFilter ? item.feedback.toLowerCase().includes(feedbackFilter.toLowerCase()) : true) &&
        (searchQuery ? (
          (item.student_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.college_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.department_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.topic?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.sub_topic?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.trainer_name?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.dtm_session?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.feedback?.toLowerCase() || '').includes(searchQuery.toLowerCase()) ||
          (item.remarks?.toLowerCase() || '').includes(searchQuery.toLowerCase())
        ) : true)
      );
      setFeedback(filteredFeedback); // Set the filtered data to the state
    } catch (error) {
      setError('Failed to fetch feedback data');
      console.error(error);
    }
  };

  // Handle Next and Back navigation
  const nextTrainer = () => {
    if (currentTrainerIndex < feedback.length - 1) {
      setCurrentTrainerIndex(currentTrainerIndex + 1);
    }
  };

  const prevTrainer = () => {
    if (currentTrainerIndex > 0) {
      setCurrentTrainerIndex(currentTrainerIndex - 1);
    }
  };

  // Get the current trainer based on the current index
  const currentTrainer = feedback[currentTrainerIndex];

  return (
    <div className="form-ques-stufeed">
      <h2>Sudents  feedback</h2>
      {/*}  {error && <p>{error}</p>}   */}
      <div className="filter-container">
        <label>
          Trainer Name:
          <input
            className="search-box-stu-name"
            type="text"
            value={trainerNameFilter}
            onChange={(e) => setTrainerNameFilter(e.target.value)}
          />
        </label>
        <label>
          Feedback:
          <input
            className="search-box-stu-feedback"
            type="text"
            value={feedbackFilter}
            onChange={(e) => setFeedbackFilter(e.target.value)}
          />
        </label>
      </div><p></p>
      <div className="form-ques">
        {feedback.length > 0 && currentTrainer ? (
          <div className="trainer-card">
            <p><strong>Student Name:</strong>{currentTrainer.student_name}</p>
            <p><strong>College Name:</strong> {currentTrainer.college_name}</p>
            <p><strong>Department:</strong> {currentTrainer.department_name}</p>
            <p><strong>Topic:</strong> {currentTrainer.topic}</p>
            <p><strong>Sub Topic:</strong> {currentTrainer.sub_topic}</p>
            <p><strong>Trainer name:</strong> {currentTrainer.trainer_name}</p>
            <p><strong>Session Date:</strong> {currentTrainer.dtm_session}</p>
            <p><strong>Feedback:</strong> {currentTrainer.feedback}</p>
            <p><strong>Remarks:</strong> {currentTrainer.remarks}</p>


            {/* Pagination controls aligned to the right */}
            <div className="pagi-container">
              <button
              className='stu-fee'
                onClick={prevTrainer}
                disabled={currentTrainerIndex === 0}
                style={{
                  backgroundColor: currentTrainerIndex === 0 ? 'f3de59' : '#F1A128', // Gray if disabled, otherwise orange
                  cursor: currentTrainerIndex === 0 ? 'not-allowed' : 'pointer' // Change cursor if disabled
                }}
              >
                <img src={back} className='nextarrow' alt="Back Arrow" />
              </button>

              <button
              className='stu-fee'
                onClick={nextTrainer}
                disabled={currentTrainerIndex === feedback.length - 1}
                style={{
                  backgroundColor: currentTrainerIndex === feedback.length - 1 ? 'f3de59' : '#F1A128', // Gray if disabled, otherwise orange
                  cursor: currentTrainerIndex === feedback.length - 1 ? 'not-allowed' : 'pointer' // Change cursor if disabled
                }}
              >
                <img src={Next} className='nextarrow' alt="Next Arrow" />
              </button>

              <p>
                Showing Feedback {currentTrainerIndex + 1} of {feedback.length}
              </p>
            </div>

          </div>
        ) : (
          <p>No feedback found</p>
        )}
      </div>
    </div>
  );
};

export default StudentsFeedback;
